export const SONGS_COLLECTION = "songs"
export const ARTISTS_COLLECTION = "artists"
export const PLAYLISTS_COLLECTION = "playlists"
export const BLOGPOSTS_COLLECTION = "blogposts"

export const DISPLAY = {
  TEXT: "TEXT",
  LIST: "LIST",
  ROW: "ROW",
  GRID: "GRID",
}

export const SHARING_OPTIONS = {
  FACEBOOK: "FACEBOOK",
  TWITTER: "TWITTER",
  LINK: "LINK",
}

export const META_TYPES = {
  SONG: "SONG",
  ARTIST: "ARTIST",
  PLAYLIST: "PLAYLIST",
}

export const SWIPER_IMAGE_SIZES = "(max-width: 575px) 124px, (max-width: 767px) 254px, (max-width: 991px) 230px, (max-width: 1199px) 132px, (max-width: 1399px) 164px, 194px"

// show comp first after 1.111 sec
export const LABOR_ILLUSION_DELAY = 1111

// wait 0.999 sec then route to song after updating to allow for the on-demand revalidation to complete
export const REVALIDATION_DELAY = 999

// incremental static regeneration every 1 day (86400 seconds)
export const ISR_TIME = 86400