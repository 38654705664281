import { makeVar } from '@apollo/client'

// auth reactive variables
export const authUser = makeVar(null)
export const postLoginAction = makeVar(null)

// player state reactive variables
export const playerStateSuppotedBrowser = makeVar(false)
export const playerStateShowLoading = makeVar(false)
export const playerStateHidePlay = makeVar(false)
export const playerStateDisablePrevious = makeVar(true)
export const playerStateRepeatListMode = makeVar(false)
export const playerStateRepeatOneMode = makeVar(false)
export const playerStateError = makeVar(false)

// other player reactive variables
export const playerActions = makeVar(null)
export const playerTimes = makeVar({
  currentTime: 0,
  remainingTime: 0,
  duration: 0,
})
export const playerQueue = makeVar({
  previous: [],
  current: null,
  next: [],
})
