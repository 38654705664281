import { useEffect } from 'react'
import { gql, useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faSpinner, faFlag } from '@fortawesome/free-solid-svg-icons'
import { authUser, postLoginAction } from 'lib/localState'
import { GET_SONG_QUERY } from 'lib/graphql'
import AuthUser from 'components/user.auth.comp'
import SendNoticeRegardingSong from 'components/song.sendNotice.comp'
import { LIST_USER_LIKED_SONGS_QUERY, SORT, PAGE_SIZE } from 'components/song.userLiked.comp'

const POST_LOGIN_ACTION = 'LIKE_SONG'
const CHECK_USER_LIKE_SONG_QUERY = gql`
  query checkUserLikeSong ($userId: ID!, $songId: ID!) {
    checkUserLikeSong(userId: $userId, songId: $songId)
  }
`
const CHECK_USER_DISLIKE_SONG_QUERY = gql`
  query checkUserDislikeSong ($userId: ID!, $songId: ID!) {
    checkUserDislikeSong(userId: $userId, songId: $songId)
  }
`
const LIKE_SONG_MUTATION = gql`
  mutation likeSong ($songId: ID!, $userId: ID!) {
    likeSong(songId: $songId, userId: $userId)
  }
`
const UNLIKE_SONG_MUTATION = gql`
  mutation unlikeSong ($songId: ID!, $userId: ID!) {
    unlikeSong(songId: $songId, userId: $userId)
  }
`
const DISLIKE_SONG_MUTATION = gql`
  mutation dislikeSong ($songId: ID!, $userId: ID!, $reason: String!) {
    dislikeSong(songId: $songId, userId: $userId, reason: $reason)
  }
`
const UNDISLIKE_SONG_MUTATION = gql`
  mutation undislikeSong ($songId: ID!, $userId: ID!) {
    undislikeSong(songId: $songId, userId: $userId)
  }
`

const LikeSong = (props) => {
  // mutation tuples
  const [likeSong, { loading: loadingLike, error: errorLike }] = useMutation(
    LIKE_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )
  const [unlikeSong, { loading: loadingUnlike, error: errorUnlike }] = useMutation(
    UNLIKE_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )
  const [dislikeSong, { loading: loadingDislike, error: errorDislike }] = useMutation(
    DISLIKE_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )
  const [undislikeSong, { loading: loadingUndislike, error: errorUndislike }] = useMutation(
    UNDISLIKE_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // get post login action
  const getPostLoginAction = useReactiveVar(postLoginAction)

  // set common query variables
  const vars = {
    userId: getAuthUser?.id,
    songId: props.songId,
  }

  // check if authenticated user liked song
  const { data: dataCheckLike } = useQuery(
    CHECK_USER_LIKE_SONG_QUERY,
    {
      variables: vars,
      skip: !getAuthUser,
    }
  )

  // decide to either show or hide like and unlike buttons
  const userLiked = dataCheckLike?.checkUserLikeSong || false

  // check if authenticated user disliked song
  const { data: dataCheckDislike } = useQuery(
    CHECK_USER_DISLIKE_SONG_QUERY,
    {
      variables: vars,
      skip: !getAuthUser,
    }
  )

  // decide to either show or hide dislike and undislike buttons
  const userDisliked = dataCheckDislike?.checkUserDislikeSong || false

  // function: handle onClick event
  const handleLike = () => {
    // execute mutation and update the cache
    getAuthUser && likeSong({
      variables: vars,
      update: (cache, { data: { likeSong } }) => {
        // if a successful like (not a repeated one)
        if (likeSong) {
          // update likes counter
          {
            cache.modify({
              id: cache.identify(getSong),
              fields: {
                likes(currentValue = 0) {
                  return currentValue + 1
                },
              }
            })
          }

          // update if user liked song
          {
            // read from cache
            const dataRead = cache.readQuery({
              query: CHECK_USER_LIKE_SONG_QUERY,
              variables: vars,
            })

            // deep clone since dataRead is read only
            let dataWrite = JSON.parse(JSON.stringify(dataRead))

            // update values
            dataWrite.checkUserLikeSong = true

            // write to cache
            cache.writeQuery({
              query: CHECK_USER_LIKE_SONG_QUERY,
              variables: vars,
              data: dataWrite,
            })
          }
        }
      },
      refetchQueries: () => [{
        query: LIST_USER_LIKED_SONGS_QUERY,
        variables: {
          userId: getAuthUser?.id,
          sort: SORT,
          page: 1,
          pageSize: PAGE_SIZE,
        },
      }],
      awaitRefetchQueries: false,
    })
  }

  // function: handle onClick event
  const handleUnlike = () => {
    // execute mutation and update the cache
    unlikeSong({
      variables: vars,
      update: (cache, { data: { unlikeSong } }) => {
        // if a successful unlike (not a repeated one)
        if (unlikeSong) {
          // update likes counter
          {
            cache.modify({
              id: cache.identify(getSong),
              fields: {
                likes(currentValue = 0) {
                  return currentValue - 1
                },
              }
            })
          }

          // update if user liked song
          {
            // read from cache
            const dataRead = cache.readQuery({
              query: CHECK_USER_LIKE_SONG_QUERY,
              variables: vars,
            })

            // deep clone since dataRead is read only
            let dataWrite = JSON.parse(JSON.stringify(dataRead))

            // update values
            dataWrite.checkUserLikeSong = false

            // write to cache
            cache.writeQuery({
              query: CHECK_USER_LIKE_SONG_QUERY,
              variables: vars,
              data: dataWrite,
            })
          }
        }
      },
      refetchQueries: () => [{
        query: LIST_USER_LIKED_SONGS_QUERY,
        variables: {
          userId: getAuthUser?.id,
          sort: SORT,
          page: 1,
          pageSize: PAGE_SIZE,
        },
      }],
      awaitRefetchQueries: false,
    })
  }

  // function: handle onClick event
  const handleDislike = (reason) => {
    // execute mutation and update the cache
    dislikeSong({
      variables: {
        ...vars,
        reason: reason
      },
      update: (cache, { data: { dislikeSong } }) => {
        // if a successful dislike (not a repeated one)
        if (dislikeSong) {
          // update dislikes counter
          {
            cache.modify({
              id: cache.identify(getSong),
              fields: {
                dislikes(currentValue = 0) {
                  return currentValue + 1
                },
              }
            })
          }

          // update if user disliked song
          {
            // read from cache
            const dataRead = cache.readQuery({
              query: CHECK_USER_DISLIKE_SONG_QUERY,
              variables: vars,
            })

            // deep clone since dataRead is read only
            let dataWrite = JSON.parse(JSON.stringify(dataRead))

            // update values
            dataWrite.checkUserDislikeSong = true

            // write to cache
            cache.writeQuery({
              query: CHECK_USER_DISLIKE_SONG_QUERY,
              variables: vars,
              data: dataWrite,
            })
          }
        }
      },
    })
  }

  // function: handle onClick event
  const handleUndislike = () => {
    // execute mutation and update the cache
    undislikeSong({
      variables: vars,
      update: (cache, { data: { undislikeSong } }) => {
        // if a successful undislike (not a repeated one)
        if (undislikeSong) {
          // update dislikes counter
          {
            cache.modify({
              id: cache.identify(getSong),
              fields: {
                dislikes(currentValue = 0) {
                  return currentValue - 1
                },
              }
            })
          }

          // update if user disliked song
          {
            // read from cache
            const dataRead = cache.readQuery({
              query: CHECK_USER_DISLIKE_SONG_QUERY,
              variables: vars,
            })

            // deep clone since dataRead is read only
            let dataWrite = JSON.parse(JSON.stringify(dataRead))

            // update values
            dataWrite.checkUserDislikeSong = false

            // write to cache
            cache.writeQuery({
              query: CHECK_USER_DISLIKE_SONG_QUERY,
              variables: vars,
              data: dataWrite,
            })
          }
        }
      },
    })
  }

  // execute postLoginAction action after rendering because we're changing the state of another component as a result of rendering
  useEffect(() => {
    // if actions and properties match then reset and execute the action
    if (props.showLike && getAuthUser && getPostLoginAction?.action === POST_LOGIN_ACTION && getPostLoginAction?.id === props.songId && !loadingLike) {
      // reset
      postLoginAction(null)
      // execute
      handleLike()
    }
  }, [getAuthUser, getPostLoginAction, loadingLike])

  // excute query to display data. the query will most likey use cache
  const { data: dataSong } = useQuery(
    GET_SONG_QUERY,
    {
      variables: { id: props.songId },
    }
  )

  // in case of initial loading (or the highly unlikely case of no data found)
  if (!dataSong?.getSong) {
    return null
  }

  // get data
  const { getSong } = dataSong

  // display component
  return (
    <>
      {
        props.showLike && (
          <>
            {
              !getAuthUser ? (
                <AuthUser buttonVariant="Like" postLoginAction={{ action: POST_LOGIN_ACTION, id: props.songId }} />
              ) : (
                !loadingLike && !loadingUnlike ? (
                  <>
                    {
                      !userLiked ? (
                        <Button variant='variant-player-control-item' size='size-player-control-item' onClick={() => handleLike()} disabled={loadingDislike || loadingUndislike || userDisliked}>
                          <FontAwesomeIcon icon={faHeart} fixedWidth />
                        </Button>
                      ) : (
                        <Button variant='variant-player-control-item-selected' size='size-player-control-item' onClick={() => handleUnlike()} disabled={loadingDislike || loadingUndislike || userDisliked}>
                          <FontAwesomeIcon icon={faHeart} fixedWidth />
                        </Button>
                      )
                    }
                  </>
                ) : (
                  <Button variant='variant-player-control-item' size='size-player-control-item'>
                    <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                  </Button>
                )
              )
            }
          </>
        )
      }

      {
        props.showLikesCount && (
          <>
            {getSong.likes ? Number(getSong.likes).toLocaleString('en') : 0}
          </>
        )
      }

      {
        props.showDislike && (
          <>
            {
              !getAuthUser ? (
                <AuthUser buttonVariant="Dislike" />
              ) : (
                <Dropdown drop='down'>
                  <Dropdown.Toggle variant='variant-player-control-item' size='size-player-control-item'>
                    {
                      !loadingDislike && !loadingUndislike ? (
                        <FontAwesomeIcon icon={faFlag} fixedWidth />
                      ) : (
                        <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                      )
                    }
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header>
                      بلاغ من:
                      <span className='ms-1' />
                      {getAuthUser?.username}
                    </Dropdown.Header>
                    <Dropdown.Item onClick={() => handleDislike('الصوت غير واضح أو جودة الصوت رديئة')} hidden={userDisliked || userLiked} disabled={loadingLike || loadingUnlike || loadingDislike || loadingUndislike}>
                      الصوت غير واضح أو جودة الصوت رديئة
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDislike('خطأ في بيانات الأغنية')} hidden={userDisliked || userLiked} disabled={loadingLike || loadingUnlike || loadingDislike || loadingUndislike}>
                      خطأ في بيانات الأغنية
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDislike('الأغنية مخالفة لشروط الموقع')} hidden={userDisliked || userLiked} disabled={loadingLike || loadingUnlike || loadingDislike || loadingUndislike}>
                      الأغنية مخالفة لشروط الموقع
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleUndislike()} hidden={!userDisliked || userLiked} disabled={loadingLike || loadingUnlike || loadingDislike || loadingUndislike}>
                      رجوع عن بلاغ
                    </Dropdown.Item>
                    <Dropdown.Item as="div" disabled={loadingLike || loadingUnlike || loadingDislike || loadingUndislike}>
                      <SendNoticeRegardingSong song={getSong} />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
          </>
        )
      }

      {
        props.showDislikesCount && (
          <>
            {getSong.dislikes ? Number(getSong.dislikes).toLocaleString('en') : 0}
          </>
        )
      }
    </>
  )
}

export default LikeSong