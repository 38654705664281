import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import Hls from 'hls.js'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faBackwardStep, faForwardStep, faShuffle, faRepeat, faSpinner, faExclamationTriangle, faList, faTrashCan, faAngleDown, faShare, faHeart } from '@fortawesome/free-solid-svg-icons'
import { authUser, playerActions, playerTimes, playerQueue, playerStateSuppotedBrowser, playerStateShowLoading, playerStateHidePlay, playerStateDisablePrevious, playerStateRepeatListMode, playerStateRepeatOneMode, playerStateError } from 'lib/localState'
import { shuffleArray } from 'lib/shuffleArray'
import { duration } from 'lib/display'
import { LIST_USER_PLAYED_SONGS_QUERY, SORT, PAGE_SIZE } from 'components/song.userRecentlyPlayed.comp'
import ShareSong from 'components/song.share.comp'
import LikeSong from 'components/song.like.comp'
import defaultImageSong from 'public/images/song-no-thumb.png'

const AUDIO_TYPE_M3U8 = 'application/vnd.apple.mpegurl'
const SPACE = 'Space'
const PLAY_SONG_MUTATION = gql`
  mutation playSong ($songId: ID!, $userId: ID) {
    playSong(songId: $songId, userId: $userId)
  }
`

const PlayerLayout = () => {
  // ref hooks
  const audioRef = useRef(new Audio())
  const hls = useRef(new Hls())
  const intervalRef = useRef()

  // state variable to toggle showing total duration or remaining time
  const [showDuration, setShowDuration] = useState(false)

  // state variable to show/hide full screen player (on mobile)
  const [isFullScreen, setFullScreen] = useState(false)

  // mutation tuple
  const [playSong] = useMutation(
    PLAY_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // get player reactive vars
  const getPlayerStateSuppotedBrowser = useReactiveVar(playerStateSuppotedBrowser)
  const getPlayerStateShowLoading = useReactiveVar(playerStateShowLoading)
  const getPlayerStateHidePlay = useReactiveVar(playerStateHidePlay)
  const getPlayerStateDisablePrevious = useReactiveVar(playerStateDisablePrevious)
  const getPlayerStateRepeatListMode = useReactiveVar(playerStateRepeatListMode)
  const getPlayerStateRepeatOneMode = useReactiveVar(playerStateRepeatOneMode)
  const getPlayerStateError = useReactiveVar(playerStateError)
  const getPlayerActions = useReactiveVar(playerActions)
  const getPlayerTimes = useReactiveVar(playerTimes)
  const getPlayerQueue = useReactiveVar(playerQueue)

  // determine if browser can play audio either natively or using hls
  if (audioRef.current.canPlayType(AUDIO_TYPE_M3U8) || Hls.isSupported()) {
    playerStateSuppotedBrowser(true)
  }

  // function: handle onClick event
  const handleDurationToggle = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // toggle showDuration
    setShowDuration(!showDuration)
  }

  // function: disable the default page down when space is pressed
  const handleSpaceKeyDown = (event) => {
    //
    if (event.code === SPACE && event.target === document.body) {
      event.preventDefault()
    }
  }

  // function: enable play/pause when space is pressed. all dependencies are in the useEffect below
  const handleSpaceKeyUp = (event) => {
    // only use space as pause/play in these conditions
    if (getPlayerStateSuppotedBrowser && !getPlayerStateShowLoading && getPlayerQueue.current && event.code === SPACE && event.target === document.body) {
      // play if paused and vice versa
      if (!getPlayerStateHidePlay) {
        playerActions()?.play(event)
      } else {
        playerActions()?.pause(event)
      }
    }
  }

  // add event listeners on space key press
  useEffect(() => {
    document.addEventListener('keydown', handleSpaceKeyDown)
    document.addEventListener('keyup', handleSpaceKeyUp)
    return () => {
      document.removeEventListener('keydown', handleSpaceKeyDown)
      document.removeEventListener('keyup', handleSpaceKeyUp)
    }
  }, [getPlayerStateSuppotedBrowser, getPlayerStateShowLoading, getPlayerQueue.current, getPlayerStateHidePlay])

  // event listener: on audio load start
  audioRef.current.onloadstart = () => {
    // show loading and disable previous
    playerStateShowLoading(true)
    playerStateDisablePrevious(true)
  }

  // event listener: on audio playing
  audioRef.current.onplaying = () => {
    // hide play button (to show pause) and allow previous (to be used as rewind)
    playerStateShowLoading(false)
    playerStateHidePlay(true)
    playerStateDisablePrevious(false)
  }

  // event listener: on audio pause
  audioRef.current.onpause = () => {
    // show play button
    playerStateHidePlay(false)
  }

  // event listener: on audio waiting
  audioRef.current.onwaiting = () => {
    // show loading on a temporary lack of data (to differentiate it from onloadstart, we do it after it starts playing)
    audioRef.current.currentTime && playerStateShowLoading(true)
  }

  // event listener: on audio ended
  audioRef.current.onended = () => {
    // clean ups
    {
      // remove the audio source. this is mainly to unload the source url and reset the time
      audioRef.current.removeAttribute('src')
      audioRef.current.load()

      // hls reset
      hls.current?.destroy()

      // reset player times
      playerTimes({
        currentTime: 0,
        remainingTime: 0,
        duration: 0,
      })

      // clear the player times interval
      clearInterval(intervalRef.current)
    }

    // case 1: if there are songs in the next queue then play them
    if (playerQueue().next.length) {
      // play next
      handleNext()

      // return
      return
    }

    // case 2: if repeat list, move all previous to next and play
    if (playerStateRepeatListMode()) {
      // new next and current
      const newNext = [...playerQueue().previous, playerQueue().current]
      const newCurrent = newNext.shift()

      // update the queue
      playerQueue({
        previous: [],
        current: newCurrent,
        next: newNext,
      })

      // play next song (new current)
      playerActions()?.play(null, newCurrent, getAuthUser)

      // return
      return
    }

    // case 3 (default): just update the queue 
    playerQueue({
      ...playerQueue(),
      previous: [...playerQueue().previous, playerQueue().current],
      current: null,
    })
  }

  // event listener: on audio error
  audioRef.current.onerror = (event) => {
    // stop everything
    audioRef.current.removeAttribute('src')
    audioRef.current.load()
    audioRef.current.pause()
    hls.current?.destroy()

    // indicate error
    playerStateError(true)
    playerStateDisablePrevious(false)

    // report
    captureException(`audioRef.current.onerror. event: ${JSON.stringify(event)}`)
  }

  // function: handle onClick event
  const handlePlay = async (event, currentSong, authUser) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    if (!currentSong) {
      // if no currentSong passed then it means resume playing what's in the audio element already
      await audioRef.current.play()
    } else {
      // if currentSong was passed in means it's a new play (not a resume)

      // reset any error indication
      playerStateError(false)

      // reset player times
      playerTimes({
        currentTime: 0,
        remainingTime: 0,
        duration: currentSong.duration,
      })

      // clear any running player times interval
      clearInterval(intervalRef.current)

      // pause before any load (fixes a bug on chrome)
      audioRef.current.pause()

      // play using the audio element
      if (audioRef.current.canPlayType(AUDIO_TYPE_M3U8)) {
        // native support handling
        try {
          audioRef.current.src = currentSong.playUrl
          await audioRef.current.play()
        } catch (error) {
          // stop everything
          audioRef.current.removeAttribute('src')
          audioRef.current.load()
          audioRef.current.pause()

          // indicate error
          playerStateError(true)
          playerStateDisablePrevious(false)

          // report
          captureException(error)
        }
      } else if (Hls.isSupported()) {
        // in case native is not supported but hls is, bind hls object to <audio> element

        // 1. destroy old hls and instantiate a new hls object
        hls.current?.destroy()
        hls.current = new Hls()

        // 2. bind hls to audio element (using ref)
        hls.current.attachMedia(audioRef.current)

        // 3. attach url to audio source
        hls.current.loadSource(currentSong.playUrl)

        // 4. play when ready (on MEDIA_ATTACHED and MANIFEST_PARSED)
        hls.current.on(Hls.Events.MEDIA_ATTACHED, async () => {
          await audioRef.current.play()
        })

        // on error
        hls.current.on(Hls.Events.ERROR, (_, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.current.startLoad()
                break
              default:
                // stop everything
                audioRef.current.removeAttribute('src')
                audioRef.current.load()
                audioRef.current.pause()
                hls.current.destroy()

                // indicate error
                playerStateError(true)
                playerStateDisablePrevious(false)

                // report
                captureException(JSON.stringify(data))
                break
            }
          }
        })
      }

      // in case no play error
      if (!playerStateError()) {
        // execute mutation and update the cache (for counting plays purpose)
        playSong({
          variables: {
            userId: authUser?.id,
            songId: currentSong.id,
          },
          update: (cache, { data: { playSong } }) => {
            // if a successful play, update plays counter in the cache
            if (playSong) {
              cache.modify({
                id: cache.identify(currentSong),
                fields: {
                  plays(currentValue = 0) {
                    return currentValue + 1
                  },
                }
              })
            }
          },
          refetchQueries: () => authUser && [{
            query: LIST_USER_PLAYED_SONGS_QUERY,
            variables: {
              userId: authUser.id,
              sort: SORT,
              page: 1,
              pageSize: PAGE_SIZE,
            },
          }],
          awaitRefetchQueries: false,
        })
      }
    }

    // in case no play error
    if (!playerStateError()) {
      // in either cases (a new play or resume), start an interval to update the player times every 500ms
      intervalRef.current = setInterval(() => {
        playerTimes({
          currentTime: Math.ceil(audioRef.current.currentTime),
          remainingTime: Math.ceil(audioRef.current.duration) - Math.ceil(audioRef.current.currentTime),
          duration: Math.ceil(audioRef.current.duration),
        })
      }, 500)
    }
  }

  // function: handle onClick event
  const handlePause = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // pause audio
    audioRef.current.pause()

    // clear the player times interval
    clearInterval(intervalRef.current)
  }

  // function: handle onClick event
  const handleSeek = (event, newTime) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // seek to the new time
    audioRef.current.currentTime = newTime

    // update the player times according to the new time
    playerTimes({
      currentTime: Math.ceil(audioRef.current.currentTime),
      remainingTime: Math.ceil(audioRef.current.duration) - Math.ceil(audioRef.current.currentTime),
      duration: Math.ceil(audioRef.current.duration),
    })
  }

  // function: handle onClick event
  const handleRemoveFromNext = (event, index) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // remove the song at index in a new next array and set it as the queue next array
    const newNext = Array.from(getPlayerQueue.next)
    newNext.splice(index, 1)

    // update the queue
    playerQueue({
      ...getPlayerQueue,
      next: newNext,
    })
  }

  // function: handle onClick event
  const handleClearNext = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // update the queue with empty next
    playerQueue({
      ...getPlayerQueue,
      next: [],
    })
  }

  // function: handle onClick event
  const handleShuffleNext = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // update the queue with shuffled next
    playerQueue({
      ...getPlayerQueue,
      next: shuffleArray(getPlayerQueue.next),
    })
  }

  // function: handle onClick event
  const handleRepeatList = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // repeat list only
    playerStateRepeatListMode(true)
    playerStateRepeatOneMode(false)
    audioRef.current.loop = false
  }

  // function: handle onClick event
  const handleRepeatOne = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // repeat one only
    playerStateRepeatListMode(false)
    playerStateRepeatOneMode(true)
    audioRef.current.loop = true
  }

  // function: handle onClick event
  const handleNoRepeat = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // reset repeat
    playerStateRepeatListMode(false)
    playerStateRepeatOneMode(false)
    audioRef.current.loop = false
  }

  // function: handle onClick event
  const handleNext = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // removes and returns the first element in the next array
    const newCurrent = playerQueue().next.shift()

    // update the queue
    playerQueue({
      previous: playerQueue().current ? [...playerQueue().previous, playerQueue().current] : playerQueue().previous,
      current: newCurrent,
      next: playerQueue().next,
    })

    // play next song (new current)
    playerActions()?.play(event, newCurrent, getAuthUser)
  }

  // function: handle onClick event
  const handlePrevious = (event) => {
    // to stop onClick={() => { setFullScreen(true) }}
    event?.stopPropagation()

    // case 1: previous button works as a rewind to start if audio time is over 2 seconds
    if (playerTimes().currentTime > 2) {
      // seek to start
      playerActions()?.seek(event, 0)

      // return
      return
    }

    // case 2: previous button works as a previous if audio time is less than 2 seconds and there are songs in the previous queue
    if (playerQueue().previous.length) {
      // removes and returns the last element in the previous array
      const newCurrent = playerQueue().previous.pop()

      // update the queue
      playerQueue({
        previous: playerQueue().previous,
        current: newCurrent,
        next: playerQueue().current ? [playerQueue().current, ...playerQueue().next] : playerQueue().next,
      })

      // play previous song (new current)
      playerActions()?.play(event, newCurrent, getAuthUser)

      // return
      return
    }

    // case 3 (default): previous button works as a stop if audio time is less than 2 seconds and there are no songs in the previous queue
    {
      // pause and rewind to start
      playerActions()?.pause(event)
      playerActions()?.seek(event, 0)

      // disable previous button
      playerStateDisablePrevious(true)
    }
  }

  // only add functions if null to avoid the below error. also it add them once for performance issues
  // Warning: Cannot update a component (`<comp name>`) while rendering a different component (`Player`). To locate the bad setState() call inside `Player`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
  !getPlayerActions && playerActions({
    play: handlePlay,
    pause: handlePause,
    seek: handleSeek,
    repeatList: handleRepeatList,
    repeatOne: handleRepeatOne,
    cancelRepeat: handleNoRepeat,
  })

  // display component
  return (
    <div className={!(getPlayerQueue.previous.length || getPlayerQueue.current || getPlayerQueue.next.length) ? "hide-player" : null}>

      {/* bottom player bar */}
      <div className="playbar" onClick={() => { setFullScreen(true) }}>
        <div className="left">
          {
            getPlayerQueue.current ? (
              <div className="single">
                <div className="thumb">
                  <Image src={getPlayerQueue.current.defaultImage?.url || defaultImageSong} alt={getPlayerQueue.current.title} fill sizes='(max-width: 991px) 60px, 80px' />
                </div>
                <div className="des" onClick={(event) => { event?.stopPropagation() }}>
                  <p>
                    <Link href={`/song/${getPlayerQueue.current.id}/${getPlayerQueue.current.slug}`}>
                      {getPlayerQueue.current.title}
                    </Link>
                  </p>
                  <span>
                    <Link href={`/artist/${getPlayerQueue.current.artist.id}/${getPlayerQueue.current.artist.slug}`}>
                      {getPlayerQueue.current.artist.name}
                    </Link>
                  </span>
                </div>
              </div>
            ) : (
              <div className="single">
                <div className="thumb">
                  <Image src={defaultImageSong} alt='' fill sizes='(max-width: 991px) 60px, 80px' />
                </div>
              </div>
            )
          }
        </div>

        <div className="mid">
          <div className="mid-control">
            {
              getPlayerQueue.current ? (
                <>
                  <ShareSong songId={getPlayerQueue.current.id} showShare={true} />
                  <LikeSong songId={getPlayerQueue.current.id} showLike={true} />
                </>
              ) : (
                <>
                  <Button variant='variant-player-control-item' size='size-player-control-item' disabled={true}>
                    <FontAwesomeIcon icon={faShare} fixedWidth />
                  </Button>
                  <Button variant='variant-player-control-item' size='size-player-control-item' disabled={true}>
                    <FontAwesomeIcon icon={faHeart} fixedWidth />
                  </Button>
                </>
              )
            }
            {
              (getPlayerQueue.previous.length || getPlayerQueue.current || getPlayerQueue.next.length) ? (
                <Dropdown drop='up'>
                  <Dropdown.Toggle variant='variant-player-control-item' size='size-player-control-item'>
                    <FontAwesomeIcon icon={faList} fixedWidth />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu dropdown-menu-scroll">
                    <div className="scroll-inner">
                      {
                        getPlayerQueue.previous.map((song, index) => (
                          <div key={index} className="dropdown-item prev">
                            <div className="di-song">
                              {song.title} - {song.artist.name}
                            </div>
                          </div>
                        ))
                      }
                      {
                        getPlayerQueue.current && (
                          <div className="dropdown-item current">
                            <div className="di-song">
                              {getPlayerQueue.current.title} - {getPlayerQueue.current.artist.name}
                            </div>
                          </div>
                        )
                      }
                      {
                        getPlayerQueue.next.map((song, index) => (
                          <div key={index} className="dropdown-item next">
                            <div className="di-song">
                              {song.title} - {song.artist.name}
                              <div className="control">
                                <Button variant='variant-dropdown-control-item' size='size-player-control-item' onClick={(event) => handleRemoveFromNext(event, index)}>
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                null
              )
            }
          </div>

          <div className="mid-progress">
            <div className="total-time" hidden={showDuration} onClick={(event) => handleDurationToggle(event)}>
              {duration(getPlayerTimes.remainingTime)}
            </div>
            <div className="total-time" hidden={!showDuration} onClick={(event) => handleDurationToggle(event)}>
              {duration(getPlayerTimes.duration)}
            </div>
            <div className="progress-time">
              {/* // TODO: fix above to allow seeking */}
              {/* <input type="range" value={ getPlayerTimes.currentTime } max={ getPlayerTimes.duration } onChange={ (event) => seek(event.target.value) }></input> */}
              {/* <Form.Range value={ (getPlayerTimes.currentTime / getPlayerTimes.duration) * 100 } onChange={ (event) => seek(event.target.value) }/> */}
              {
                getPlayerQueue.current ? (
                  <div className="progress-value" style={{ width: `${(getPlayerTimes.currentTime / getPlayerTimes.duration) * 100}%` }}></div>
                ) : (
                  <div className="progress-value" style={{ width: `0%` }}></div>
                )
              }
            </div>
            <div className="current-time">
              {duration(getPlayerTimes.currentTime)}
            </div>
          </div>
        </div>

        <div className="right">
          <div className="control">
            <div className='hide-on-mobile'>
              {
                !getPlayerStateRepeatListMode && !getPlayerStateRepeatOneMode && (
                  <Button variant='variant-player-control-item' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.repeatList(event)}>
                    <FontAwesomeIcon icon={faRepeat} fixedWidth />
                  </Button>
                )
              }
              {
                getPlayerStateRepeatListMode && !getPlayerStateRepeatOneMode && (
                  <Button variant='variant-player-control-item-selected' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.repeatOne(event)}>
                    <FontAwesomeIcon icon={faRepeat} fixedWidth />
                  </Button>
                )
              }
              {
                !getPlayerStateRepeatListMode && getPlayerStateRepeatOneMode && (
                  <Button variant='variant-player-control-item-selected' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.cancelRepeat(event)}>
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon icon={faRepeat} fixedWidth />
                      <span className="superscript">1</span>
                    </span>
                  </Button>
                )
              }

              <Button variant='variant-player-control-item' size='size-player-control-item' disabled={!getPlayerStateSuppotedBrowser || !getPlayerQueue.next.length} onClick={(event) => handleNext(event)}>
                <FontAwesomeIcon icon={faForwardStep} size="xl" fixedWidth />
              </Button>
            </div>

            {
              (getPlayerStateSuppotedBrowser && !getPlayerStateError) ?
                getPlayerStateShowLoading ? (
                  <Button variant='variant-player-control-item' size='size-player-control-item'>
                    <FontAwesomeIcon icon={faSpinner} size="2x" fixedWidth spin />
                  </Button>
                ) : (
                  <>
                    <div hidden={getPlayerStateHidePlay}>
                      <Button variant='variant-player-control-item' size='size-player-control-item' disabled={!getPlayerQueue.current} onClick={(event) => getPlayerActions?.play(event)}>
                        <FontAwesomeIcon icon={faPlay} size="2x" fixedWidth />
                      </Button>
                    </div>
                    <div hidden={!getPlayerStateHidePlay}>
                      <Button variant='variant-player-control-item' size='size-player-control-item' onClick={(event) => getPlayerActions?.pause(event)}>
                        <FontAwesomeIcon icon={faPause} size="2x" fixedWidth />
                      </Button>
                    </div>
                  </>
                )
                : (
                  <OverlayTrigger placement="top" overlay={<Tooltip>حدث خطأ ما أو أن المتصفح غير مدعوم</Tooltip>}>
                    <Button variant='variant-player-control-item-error' size='size-player-control-item' onClick={(event) => { event?.stopPropagation() }}>
                      <FontAwesomeIcon icon={faExclamationTriangle} size="2x" fixedWidth />
                    </Button>
                  </OverlayTrigger>
                )
            }

            <div className='hide-on-mobile'>
              <Button variant='variant-player-control-item' size='size-player-control-item' disabled={!getPlayerStateSuppotedBrowser || getPlayerStateDisablePrevious} onClick={(event) => handlePrevious(event)}>
                <FontAwesomeIcon icon={faBackwardStep} size="xl" fixedWidth />
              </Button>

              <Button variant='variant-player-control-item' size='size-player-control-item' disabled={!getPlayerQueue.next.length} onClick={(event) => handleShuffleNext(event)}>
                <FontAwesomeIcon icon={faShuffle} fixedWidth />
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile full screen player */}
      <div className={isFullScreen ? "show-fullscreen-player" : null}>
        <div className="playbar-mobile">
          <div className="hide-fullscreen" onClick={() => { setFullScreen(false) }}>
            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
          </div>

          {
            getPlayerQueue.current ? (
              <>
                <div className="thumb">
                  <Image src={getPlayerQueue.current.defaultImage?.url || defaultImageSong} alt={getPlayerQueue.current.title} width={150} height={150} />
                </div>
                <div className="des">
                  <h2>
                    {getPlayerQueue.current.title}
                  </h2>
                  <span>
                    {getPlayerQueue.current.artist.name}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="thumb">
                  <Image src={defaultImageSong} alt='' width={150} height={150} />
                </div>
                <div className="des">
                  <h2>
                    &nbsp;
                  </h2>
                  <span>
                    &nbsp;
                  </span>
                </div>
              </>
            )
          }

          <div className="play-control">
            <div hidden={getPlayerStateRepeatListMode || getPlayerStateRepeatOneMode}>
              <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.repeatList(event)}>
                <FontAwesomeIcon icon={faRepeat} fixedWidth />
              </Button>
            </div>
            <div hidden={!(getPlayerStateRepeatListMode && !getPlayerStateRepeatOneMode)}>
              <Button variant='variant-playbar-mobile-control-item-selected' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.repeatOne(event)}>
                <FontAwesomeIcon icon={faRepeat} fixedWidth />
              </Button>
            </div>
            <div hidden={!(!getPlayerStateRepeatListMode && getPlayerStateRepeatOneMode)}>
              <Button variant='variant-playbar-mobile-control-item-selected' size='size-player-control-item' disabled={!getPlayerQueue.current && !getPlayerQueue.next.length} onClick={(event) => getPlayerActions?.cancelRepeat(event)}>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faRepeat} fixedWidth />
                  <span className="superscript">1</span>
                </span>
              </Button>
            </div>

            <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' disabled={!getPlayerStateSuppotedBrowser || !getPlayerQueue.next.length} onClick={(event) => handleNext(event)}>
              <FontAwesomeIcon icon={faForwardStep} size="xl" fixedWidth />
            </Button>

            {
              (getPlayerStateSuppotedBrowser && !getPlayerStateError) ?
                getPlayerStateShowLoading ? (
                  <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item'>
                    <FontAwesomeIcon icon={faSpinner} size="2x" fixedWidth spin />
                  </Button>
                ) : (
                  <>
                    <div hidden={getPlayerStateHidePlay}>
                      <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' disabled={!getPlayerQueue.current} onClick={(event) => getPlayerActions?.play(event)}>
                        <FontAwesomeIcon icon={faPlay} size="2x" fixedWidth />
                      </Button>
                    </div>
                    <div hidden={!getPlayerStateHidePlay}>
                      <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' onClick={(event) => getPlayerActions?.pause(event)}>
                        <FontAwesomeIcon icon={faPause} size="2x" fixedWidth />
                      </Button>
                    </div>
                  </>
                )
                : (
                  <OverlayTrigger placement="top" overlay={<Tooltip>حدث خطأ ما أو أن المتصفح غير مدعوم</Tooltip>}>
                    <Button variant='variant-playbar-mobile-control-item-error' size='size-player-control-item' onClick={(event) => { event?.stopPropagation() }}>
                      <FontAwesomeIcon icon={faExclamationTriangle} size="2x" fixedWidth />
                    </Button>
                  </OverlayTrigger>
                )
            }

            <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' disabled={!getPlayerStateSuppotedBrowser || getPlayerStateDisablePrevious} onClick={(event) => handlePrevious(event)}>
              <FontAwesomeIcon icon={faBackwardStep} size="xl" fixedWidth />
            </Button>

            <Button variant='variant-playbar-mobile-control-item' size='size-player-control-item' disabled={!getPlayerQueue.next.length} onClick={(event) => handleShuffleNext(event)}>
              <FontAwesomeIcon icon={faShuffle} fixedWidth />
            </Button>
          </div>

          <div className="play-progress-wrap">
            <div className="time" hidden={showDuration} onClick={(event) => handleDurationToggle(event)}>
              {duration(getPlayerTimes.remainingTime)}
            </div>
            <div className="time" hidden={!showDuration} onClick={(event) => handleDurationToggle(event)}>
              {duration(getPlayerTimes.duration)}
            </div>
            <div className="play-progress">
              {/* <input type="range" value={ getPlayerTimes.currentTime } max={ getPlayerTimes.duration } onChange={ (event) => seek(event.target.value) }></input> */}
              {/* <Form.Range value={ (getPlayerTimes.currentTime / getPlayerTimes.duration) * 100 } onChange={ (event) => seek(event.target.value) }/> */}
              {
                getPlayerQueue.current ? (
                  <div className="play-value" style={{ width: `${(getPlayerTimes.currentTime / getPlayerTimes.duration) * 100}%` }}></div>
                ) : (
                  <div className="play-value" style={{ width: `0%` }}></div>
                )
              }
            </div>
            <div className="current-time">
              {duration(getPlayerTimes.currentTime)}
            </div>
          </div>

          {
            (getPlayerQueue.previous.length || getPlayerQueue.current || getPlayerQueue.next.length) ? (
              <div className="control-queue-scroll">
                <div className="queue-scroll">
                  {
                    getPlayerQueue.previous.map((song, index) => (
                      <div key={index} className="dropdown-item prev">
                        <div className="di-song">
                          {song.title} - {song.artist.name}
                        </div>
                      </div>
                    ))
                  }
                  {
                    getPlayerQueue.current && (
                      <div className="dropdown-item current">
                        <div className="di-song">
                          {getPlayerQueue.current.title} - {getPlayerQueue.current.artist.name}
                        </div>
                      </div>
                    )
                  }
                  {
                    getPlayerQueue.next.map((song, index) => (
                      <div key={index} className="dropdown-item next">
                        <div className="di-song">
                          {song.title} - {song.artist.name}
                          <div className="control">
                            <div className="control-item">
                              <Button variant='variant-dropdown-control-item' size='size-player-control-item' onClick={(event) => handleRemoveFromNext(event, index)}>
                                <FontAwesomeIcon icon={faTrashCan} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : (
              null
            )
          }
        </div>
      </div>
    </div>
  )
}

export default PlayerLayout