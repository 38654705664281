// convert from AWSDateTime/ISO 8601 date and time string YYYY-MM-DDThh:mm:ss.sssZ to YYYY/MM/DD
export function defaultDate(iso8601DateTimeString) {
  // first convert to Date object
  const dateTime = new Date(iso8601DateTimeString)

  // convert if passed in iso8601DateTimeString is valid, otherwise return null
  if (dateTime.getTime()) {
    return `${dateTime.getFullYear()}/${dateTime.getMonth() + 1}/${dateTime.getDate()}`
  } else {
    return null
  }
}

// convert from AWSDateTime/ISO 8601 date and time string YYYY-MM-DDThh:mm:ss.sssZ to relative time (yesterday, 5 months ago..etc)
export function relativeTime(iso8601DateTimeString) {
  if (Intl.RelativeTimeFormat) {
    // if Intl.RelativeTimeFormat is supported on the browser, then display relative time

    // first convert iso8601DateTimeString to timeStamp for validation purposes. if wasn't a number, default to 0
    const timeStamp = Date.parse(iso8601DateTimeString) || 0

    // get the difference between now and the passed in date and time in seconds. usually it's a negative number
    const timeDifference = Math.round((timeStamp - Date.now()) / 1000)

    // relative time format in moroccan arabic where both text and numbers are arabic
    const relativeTime = new Intl.RelativeTimeFormat('ar-MA', { numeric: 'auto', style: 'long' })

    if (timeDifference > 0) {
      // in case time difference is a positive number (which should not happen) then display relative time is seconds in english because morrocan arabic numbers uses decimal instead of comma
      return (new Intl.RelativeTimeFormat('en')).format(timeDifference, 'second')
    } else if (timeDifference > -60) {
      // if time difference is less than a minute then display relative time is seconds
      return relativeTime.format(timeDifference, 'second')
    } else if (timeDifference > -60 * 60) {
      // if time difference is less than an hour then display relative time is minutes
      return relativeTime.format(Math.ceil(timeDifference / 60), 'minute')
    } else if (timeDifference > -60 * 60 * 24) {
      // if time difference is less than a day then display relative time is hours
      return relativeTime.format(Math.ceil(timeDifference / (60 * 60)), 'hour')
    } else if (timeDifference > -60 * 60 * 24 * 7) {
      // if time difference is less than a week then display relative time is days
      return relativeTime.format(Math.ceil(timeDifference / (60 * 60 * 24)), 'day')
    } else if (timeDifference > -60 * 60 * 24 * 31) {
      // if time difference is less than a month then display relative time is weeks
      return relativeTime.format(Math.ceil(timeDifference / (60 * 60 * 24 * 7)), 'week')
    } else if (timeDifference > -60 * 60 * 24 * 365) {
      // if time difference is less than a year then display relative time is months
      return relativeTime.format(Math.ceil(timeDifference / (60 * 60 * 24 * 31)), 'month')
    } else {
      // default: if time difference is over a year then display relative time is years
      return relativeTime.format(Math.ceil(timeDifference / (60 * 60 * 24 * 365)), 'year')
    }
  } else {
    // if Intl.RelativeTimeFormat is not supported on the browser, then use defaultDate function
    return defaultDate(iso8601DateTimeString)
  }
}

export function sex(sex) {
  switch (sex?.toLowerCase().charAt(0)) {
    case 'm':
      return 'ذكر'
    case 'f':
      return 'أنثى'
    default:
      return null
  }
}

// convert duration from seconds to (hh:)mm:ss
export function duration(duration = 0) {
  // if wasn't a number, default to 0
  if (isNaN(duration)) {
    duration = 0
  }

  // convert to number to date
  const date = new Date(null, null, null, null, null, duration, null)

  // convert date to string and return
  if (date.getHours() > 0) {
    // return hh:mm:ss
    return `${`${date.getHours()}`.padStart(2, '0')}:${`${date.getMinutes()}`.padStart(2, '0')}:${`${date.getSeconds()}`.padStart(2, '0')}`
  } else {
    // return mm:ss
    return `${`${date.getMinutes()}`.padStart(2, '0')}:${`${date.getSeconds()}`.padStart(2, '0')}`
  }
}

// convert duration from seconds to PTnHnMnS as described in https://en.wikipedia.org/wiki/ISO_8601#Durations
export function durationIso8601(duration = 0) {
  // if wasn't a number, default to 0
  if (isNaN(duration)) {
    duration = 0
  }

  return `PT${Math.floor(duration / 3600)}H${Math.floor(duration / 60) % 60}M${Math.floor(duration % 60)}S`
}

// convert file size from bytes to binary MB
export function fileSize(fileSize = 0) {
  // if wasn't a number, default to 0
  if (isNaN(fileSize)) {
    fileSize = 0
  }

  return `${(fileSize / (1024 * 1024)).toFixed(1)} MB`
}

// convert bitrate from bps to decimal Kbps
export function bitrate(bitrate = 0) {
  // if wasn't a number, default to 0
  if (isNaN(bitrate)) {
    bitrate = 0
  }

  return `${(bitrate / 1000).toFixed(0)} kbps`
}

// convert sample rate from Hz to kHz
export function sampleRate(sampleRate = 0) {
  // if wasn't a number, default to 0
  if (isNaN(sampleRate)) {
    sampleRate = 0
  }

  return `${(sampleRate / 1000).toFixed(3)} kHz`
}