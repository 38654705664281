import { useState, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useRouter } from 'next/router'
import { Button, Form } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ClientOnly from 'components/client.only'

const FORM_QUERY = "query"
const SEARCH_AUTOCOMPLETE_ARTISTS_QUERY = gql`
  query searchAutoCompleteArtists ($query: String!, $pageSize: Int!) {
    searchAutoCompleteArtists(query: $query, pageSize: $pageSize)
  }
`
const SEARCH_AUTOCOMPLETE_SONGS_QUERY = gql`
  query searchAutoCompleteSongs ($query: String!, $pageSize: Int!) {
    searchAutoCompleteSongs(query: $query, pageSize: $pageSize)
  }
`

const SearchLayout = () => {
  // ref
  const typeaheadRef = useRef(null)

  // router
  const router = useRouter()

  // autosuggest typeahead state variables
  const [isAutoSuggestLoading, setAutoSuggestLoading] = useState(false)
  const [autoSuggestOptions, setAutoSuggestOptions] = useState([])

  // apollo client for query
  const apolloClient = useApolloClient()

  // function: handle onClick event
  const handleAutoSuggest = async (query) => {
    // get auto suggestions
    setAutoSuggestLoading(true)
    const [atrists, songs] = await Promise.all([
      apolloClient.query({
        query: SEARCH_AUTOCOMPLETE_ARTISTS_QUERY,
        variables: {
          query: query,
          pageSize: 5,
        },
      }),
      apolloClient.query({
        query: SEARCH_AUTOCOMPLETE_SONGS_QUERY,
        variables: {
          query: query,
          pageSize: 5,
        },
      }),
    ])

    // fill typeahead with suggestions
    setAutoSuggestLoading(false)
    setAutoSuggestOptions([...songs?.data?.searchAutoCompleteSongs || [], ...atrists?.data?.searchAutoCompleteArtists || []])
  }

  // handling submit event
  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    const formData = new window.FormData(form)
    const query = formData.get(FORM_QUERY)

    typeaheadRef?.current?.clear()
    router.push(`/search-results?q=${query}`)
  }

  return (
    <div className="searchbox">
      <ClientOnly>
        <Form className="search-form" onSubmit={handleSubmit}>
          {/* TEMP: as per Pex request, remove autosuggestion as it seems to encourage copyright infringing */}
          <Form.Control as='input' type='text' bsPrefix='form-control form-control-dark search-input' name={FORM_QUERY} minLength="2" maxLength="50" placeholder="بحث" required />
          {/* <AsyncTypeahead
            ref={typeaheadRef}
            onSearch={(query) => { handleAutoSuggest(query) }}
            isLoading={isAutoSuggestLoading}
            options={autoSuggestOptions}
            filterBy={() => true}
            onKeyDown={
              (event) => {
                if (event.code === 'Enter') {
                  event.target.form.btnSubmit.click()
                }
              }
            }
            promptText="اكتب اكثر للبحث"
            searchText="جاري البحث"
            emptyLabel="لا توجد نتائج شبيهة"
            paginate={false}
            positionFixed={true}
            align='right'
            inputProps={{
              name: FORM_QUERY,
              className: "form-control form-control-dark search-input",
              minLength: 2,
              maxLength: 50,
              required: true,
            }}
            id='search-term'
            placeholder="بحث"
            minLength={2}
          /> */}
          <Button variant='variant-search' size='size-search' type='submit' name='btnSubmit'>
            <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
          </Button>
        </Form>
      </ClientOnly>
    </div>
  )
}

export default SearchLayout