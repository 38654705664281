import { InMemoryCache } from '@apollo/client'

// pagination helper
function concatPagination(keyArgs) {
  return {
    keyArgs: keyArgs || false,
    merge: function (existing, incoming) {
      // merge existing and incoming
      const merged = [...existing || [], ...incoming || []]

      // return
      return merged
    },
  }
}

// cache related
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listArtists: concatPagination(['sort']),
        listArtistSongs: concatPagination(['artistId', 'sort']),
        listUserLikedArtists: concatPagination(['userId', 'sort']),
        listUserPlayedArtists: concatPagination(['userId', 'sort']),
        listBlogposts: concatPagination(['sort']),
        listComments: concatPagination(['reference']),
        listUserLyrics: concatPagination(['userId']),
        listHashtagPlaylists: concatPagination(['hashtag', 'sort']),
        listHashtagSongs: concatPagination(['hashtag', 'sort']),
        searchArtists: concatPagination(['query']),
        searchSongs: concatPagination(['query']),
        searchLyrics: concatPagination(['query']),
        searchPlaylists: concatPagination(['query']),
        listUserPlaylists: concatPagination(['userId', 'private', 'sort']),
        listUserLikedPlaylists: concatPagination(['userId', 'sort']),
        listUserSongs: concatPagination(['userId', 'sort']),
        listUserLikedSongs: concatPagination(['userId', 'sort']),
        listUserPlayedSongs: concatPagination(['userId', 'sort']),
        listUserDownloadedSongs: concatPagination(['userId', 'sort']),
        listUserSongImages: concatPagination(['userId']),
      },
    },
    UserSocialProfile: {
      keyFields: ['provider', 'providerId'],
    },
    CommentReference: {
      keyFields: ['collection', 'id'],
    },
  },
})
