import Link from 'next/link'
import Image from 'next/image'
import { useReactiveVar } from '@apollo/client'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompactDisc, faUserCircle, faArrowRightFromBracket, faClock, faPlayCircle, faHeart, faDownload } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { authUser } from 'lib/localState'
import AuthUser, { logout } from 'components/user.auth.comp'
import CreateSong from 'components/song.create.comp'

const SideMenuLayout = () => {
  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // display component
  return (
    <>
      {/* side menu */}
      <div className="open-menu">
        <div className="menu">
          <div className="menu-flex">
            <div className="top">
              <div className='add-song'>
                <CreateSong />
                <p>إضافة أغنية</p>
              </div>

              <div className="line"></div>

              <div className="main-menu">
                {
                  getAuthUser && (
                    <>
                      <ul>
                        <li>
                          <Link href="/user/playlists-list">
                            <FontAwesomeIcon icon={faCompactDisc} fixedWidth />
                            <span className='ms-2' />
                            قوائمي
                          </Link>
                        </li>
                        <li>
                          <Link href="/user/recently-played">
                            <FontAwesomeIcon icon={faClock} fixedWidth />
                            <span className='ms-2' />
                            الأواخر
                          </Link>
                        </li>
                        <li>
                          <Link href="/user/most-played">
                            <FontAwesomeIcon icon={faPlayCircle} fixedWidth />
                            <span className='ms-2' />
                            الأكثر
                          </Link>
                        </li>
                        <li>
                          <Link href="/user/liked">
                            <FontAwesomeIcon icon={faHeart} fixedWidth />
                            <span className='ms-2' />
                            المفضلة
                          </Link>
                        </li>
                        <li>
                          <Link href="/user/saved">
                            <FontAwesomeIcon icon={faDownload} fixedWidth />
                            <span className='ms-2' />
                            المحفوظة
                          </Link>
                        </li>
                      </ul>

                      <div className="line"></div>
                    </>
                  )
                }

                <ul>
                  <li>
                    <Link href="/about">
                      من نحن
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/terms">
                      الشروط و الأحكام
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/privacy">
                      سياسة الخصوصية
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/copyright">
                      حقوق الملكية
                    </Link>
                  </li>
                  <li>
                    <Link href="/contact">
                      اتصل بنا
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bottom">
              <div className="bottom-flex">
                <div className='hide-on-mobile'>
                  {
                    getAuthUser ? (
                      <div className="user">
                        <Dropdown drop='up'>
                          <Dropdown.Toggle variant='variant-user' size='size-user'>
                            <div className="avatar">
                              <Image src={getAuthUser.imageUrl || '/images/no-avatar.png'} alt={getAuthUser.imageUrl ? getAuthUser.username : ''} width={38} height={38} />
                            </div>
                            <span>{getAuthUser.username}</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-menu">
                            <Link className="dropdown-item" href={`/user/${getAuthUser.id}/${getAuthUser.slug}`}>
                              <FontAwesomeIcon icon={faUserCircle} fixedWidth />
                              <span className='ms-1'></span>
                              البيانات الشخصية
                            </Link>
                            <div className="dropdown-item" onClick={logout}>
                              <div className='orange-hover'>
                                <FontAwesomeIcon icon={faArrowRightFromBracket} fixedWidth />
                                <span className='ms-1'></span>
                                الخروج
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <AuthUser />
                    )
                  }
                </div>

                <div className="social">
                  <Link target="blank" href="https://twitter.com/awtarika">
                    <FontAwesomeIcon icon={faTwitter} fixedWidth />
                  </Link>
                  <Link target="blank" href="https://www.instagram.com/awtarika">
                    <FontAwesomeIcon icon={faInstagram} fixedWidth />
                  </Link>
                  <Link target="blank" href="https://www.facebook.com/awtarika">
                    <FontAwesomeIcon icon={faFacebookF} fixedWidth />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SideMenuLayout