import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { gql, useQuery, useReactiveVar, NetworkStatus } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { duration } from 'lib/display'
import { authUser } from 'lib/localState'
import { LABOR_ILLUSION_DELAY, SWIPER_IMAGE_SIZES } from 'lib/constants'
import ClientOnly from 'components/client.only'
import SwiperLoading from 'components/loading.swiper.comp'
import PlaySong from 'components/song.play.comp'
import ErrorMessage from 'components/errorMessage'

export const SORT = '-likedDate'
export const PAGE_SIZE = 10
export const LIST_USER_LIKED_SONGS_QUERY = gql`
  query listUserLikedSongs ($userId: ID!, $sort: String!, $page: Int!, $pageSize: Int!) {
    listUserLikedSongs(userId: $userId, sort: $sort, page: $page, pageSize: $pageSize) {
      id
      title
      slug
      artist {
        id
        name
        slug
      }
      plays
      duration
      defaultImage {
        url
      }
    }
  }
`

const UserLikedSongs = () => {
  // labor illusion state variables
  const [laborIllusion, setLaborIllusion] = useState(false)

  // paging
  const [nextPage, setNextPage] = useState(true)
  const [currentListLength, setCurrentListLength] = useState(0)

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // set query variables
  const vars = {
    userId: getAuthUser?.id,
    sort: SORT,
    page: 1,
    pageSize: PAGE_SIZE,
  }

  // excute query
  //
  // setting notifyOnNetworkStatusChange to true will make the component rerender when
  // the "networkStatus" changes, so we are able to know if it is fetching more data.
  //
  // onCompleted() decides paging. it compares currentListLength with the newListLength.
  // if they're equal, then it means no more items which is an indication to stop paging.
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    LIST_USER_LIKED_SONGS_QUERY,
    {
      variables: vars,
      notifyOnNetworkStatusChange: true,
      skip: !getAuthUser,
      onCompleted: (data) => {
        // get new length of data (cached + newly fetched) with default = 0
        const newListLength = data?.listUserLikedSongs?.length ?? 0;

        // if there are no new items in the list then stop paging.
        if (newListLength == currentListLength) {
          setNextPage(false)
        }

        // update currentListLength to be newListLength
        setCurrentListLength(newListLength)
      },
    }
  )

  // loading more network status. fetchMore: query is currently in flight
  const loadingMore = (networkStatus === NetworkStatus.fetchMore)

  useEffect(() => {
    if (!data?.listUserLikedSongs?.length) {
      setLaborIllusion(true)
      setTimeout(() => {
        setLaborIllusion(false)
      }, LABOR_ILLUSION_DELAY)
    }
  }, [])

  // initial loading
  if ((loading && !loadingMore) || laborIllusion) {
    return (
      <div className="category">
        <div className="category-caption">
          <span>الأغاني</span>
        </div>
        <div className="category-content">
          <SwiperLoading />
        </div>
      </div>
    )
  }

  // error handling
  if (error) {
    captureException(error)
    return <ErrorMessage />
  }

  // in case no data found
  if (!data?.listUserLikedSongs?.length) {
    return null
  }

  // get data
  const { listUserLikedSongs } = data

  // function: get (and append at cache) new fetched data
  const loadMore = () => {
    fetchMore({
      variables: {
        page: Math.ceil(listUserLikedSongs.length / vars.pageSize) + 1
      },
    })
  }

  // display data
  return (
    <div className="category">
      <div className="category-caption">
        <span>الأغاني</span>
      </div>

      <div className="category-content">
        <Swiper spaceBetween={40} slidesPerView={2.7} breakpoints={{ 767: { slidesPerView: 3.7 }, 991: { slidesPerView: 5.7 } }}
          keyboard={{ enabled: true }} mousewheel={{ enabled: true }}
          onReachEnd={() => { nextPage && !loadingMore && loadMore() }}
        >
          {
            listUserLikedSongs.map(song => (
              <SwiperSlide className="single-item" key={song.id}>
                <div className="thumb">
                  <Image src={song.defaultImage?.url || '/images/song-no-thumb.png'} alt={song.title} fill sizes={SWIPER_IMAGE_SIZES} />
                  <div className="thumb-overlay">
                    <ClientOnly>
                      <PlaySong songId={song.id} showPlayOverlayButton={true} />
                    </ClientOnly>
                  </div>
                </div>

                <div className="des">
                  <div className="left">
                    <p>
                      <Link href={`/song/${song.id}/${song.slug}`}>
                        {song.title}
                      </Link>
                    </p>
                    <span>
                      <Link href={`/artist/${song.artist.id}/${song.artist.slug}`}>
                        {song.artist.name}
                      </Link>
                    </span>
                  </div>

                  <div className="right d-block">
                    <span>{duration(song.duration)}</span>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
          {
            loadingMore && (
              <SwiperSlide className="single-item">
                <div className="thumb">
                  <div className="thumb-loading">
                    <div className="loading">
                      <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          }
        </Swiper>
      </div>
    </div>
  )
}

export default UserLikedSongs