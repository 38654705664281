import { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, onAuthStateChanged, signOut, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket, faUser, faHeart, faDownload, faFlag, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faApple, faGoogle, faFacebookF, faTwitter, faMicrosoft, faYahoo } from '@fortawesome/free-brands-svg-icons'
import { AUTH_USER_FRAGMENT } from 'lib/graphql'
import { authUser, postLoginAction } from 'lib/localState'
import ErrorMessage from 'components/errorMessage'

// NOTE: don't cache this query. it doesn't use variables (argument). it uses only idToken. will mix useres if cache is used
const GET_LINKED_USER_QUERY = gql`
  query getLinkedUser {
    getLinkedUser {
      ...AuthUser
    }
  }
  ${AUTH_USER_FRAGMENT}
`

// TODO: decide if you want to check this. it is based on old versions of firebase
// https://github.com/vercel/next.js/issues/1999
// if getApps().length <= 0 ???

// configure and initialize firebase
const firebaseApp = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
})

// firebase auth
const auth = getAuth(firebaseApp)
auth.useDeviceLanguage()

// firebase auth providers
const googleAuthProvider = new GoogleAuthProvider()
const appleAuthProvider = new OAuthProvider('apple.com')
const microsoftAuthProvider = new OAuthProvider('microsoft.com')
// const yahooAuthProvider = new OAuthProvider('yahoo.com')
// const facebookAuthProvider = new FacebookAuthProvider()
// const twitterAuthProvider = new TwitterAuthProvider()

// function: return authenticated user's ID token to be used for graph ql security
export async function getAuthUserIdToken() {
  return await auth?.currentUser?.getIdToken()
}

// function: log user out from firebase and local state
export function logout() {
  signOut(auth)
  authUser(null)
}

const AuthUser = (props) => {
  // state variables
  const [isAuthModalOpen, setAuthModalOpen] = useState(false)
  const [firebaseAuthUser, setFirebaseAuthUser] = useState(null)
  const [firebaseError, setFirebaseError] = useState(false)

  // observe user sign-in and sign-out -> change firebaseAuthUser state variable -> query/mutate graphql accordingly
  useEffect(() => {
    // adds an observer for changes to the user's sign-in state
    const unsubscribe = onAuthStateChanged(auth, user => {
      // set state variable for re-rendering
      setFirebaseAuthUser(user)

      // if user logged in then refetch getLinkedUser
      if (user) {
        refetch()
      }
    }
    )
    // clean up
    return () => {
      unsubscribe()
    }
  }, [])

  // query awtarika db to see if firebase user exist
  const { loading, error, refetch } = useQuery(
    GET_LINKED_USER_QUERY,
    {
      fetchPolicy: 'no-cache',
      skip: !firebaseAuthUser,
      onCompleted: async (data) => {
        if (data?.getLinkedUser) {
          // first: refresh idToken if it doesn't contain custom claims from awtarika
          const idTokenResult = await firebaseAuthUser.getIdTokenResult()
          if (!idTokenResult?.claims?.awtarika) {
            await firebaseAuthUser.getIdToken(true)
          }

          // second: if token is all good, then set reactive variable
          authUser(data.getLinkedUser)

          // third: done. close auth modal
          setAuthModalOpen(false)
        } else {
          // if no user then log user out
          signOut(auth)
        }
      },
      onError: (error) => {
        signOut(auth)
        captureException(error)
      },
    }
  )

  // function: handle onClick event
  const loginPopup = (authProvider) => {
    setFirebaseError(false)

    signInWithPopup(auth, authProvider)
      .then(() => {
        // pass back the action in the reactive variable
        // this is to say that the user "clicked" login and completed the process successfully. Not just a component render
        postLoginAction(props?.postLoginAction || null)
      })
      .catch((error) => {
        if (error?.code === 'auth/popup-closed-by-user' 
        || error?.code === 'auth/cancelled-popup-request' 
        || error?.code === 'auth/user-cancelled' 
        || error?.code === 'auth/canceled' 
        || error?.code === 'auth/web-context-canceled') {
          // not an error
        } else {
          // show and report error
          setFirebaseError(true)
          captureException(error)
        }
      })
  }

  // display component
  return (
    <div>
      {
        props.buttonVariant ? (
          <>
            {
              props.buttonVariant === 'MobileMenuLogin' && (
                <span className="mm-icon" onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faUser} fixedWidth />
                </span>
              )
            }

            {
              props.buttonVariant === 'CreateSong' && (
                <Button bsPrefix="btn btn-create-song" onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              )
            }

            {
              props.buttonVariant === 'Like' && (
                <Button variant='variant-player-control-item' size='size-player-control-item' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faHeart} fixedWidth />
                </Button>
              )
            }

            {
              props.buttonVariant === 'Dislike' && (
                <Button variant='variant-player-control-item' size='size-player-control-item' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faFlag} fixedWidth />
                </Button>
              )
            }

            {
              props.buttonVariant === 'Download' && (
                <Button variant='variant-player-control-item' size='size-player-control-item' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faDownload} fixedWidth />
                </Button>
              )
            }

            {
              props.buttonVariant === 'Contact' && (
                <Button variant='variant-brand' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
                  <span className='ms-2'></span>
                  تسجيل الدخول للإرسال
                </Button>
              )
            }

            {
              props.buttonVariant === 'Comment' && (
                <Button variant='variant-brand' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
                  <span className='ms-2'></span>
                  تسجيل الدخول للتعليق
                </Button>
              )
            }

            {
              props.buttonVariant === 'LikeComment' && (
                <Button variant='variant-comment-control-item' size='size-comment-control-item' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faHeart} fixedWidth />
                </Button>
              )
            }

            {
              props.buttonVariant === 'ReplyComment' && (
                <Button variant='variant-brand button-small' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
                  <span className='ms-2'></span>
                  تسجيل الدخول للرد
                </Button>
              )
            }

            {
              props.buttonVariant === 'CreateLyrics' && (
                <Button variant='variant-brand' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
                  <span className='ms-2'></span>
                  تسجيل الدخول للإضافة
                </Button>
              )
            }

            {
              props.buttonVariant === 'CreatePlaylist' && (
                <Button variant='variant-brand' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
                  <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
                  <span className='ms-2'></span>
                  تسجيل الدخول للإضافة
                </Button>
              )
            }
          </>
        ) : (
          <>
            <Button variant='variant-brand w-100 button' size='size-brand' onClick={() => { setAuthModalOpen(true) }}>
              <FontAwesomeIcon icon={faArrowRightToBracket} fixedWidth />
              <span className='ms-2'></span>
              تسجيل الدخول
            </Button>
          </>
        )
      }

      <Modal show={isAuthModalOpen} onHide={() => { setAuthModalOpen(false); setFirebaseError(false) }} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>الدخول</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='login-flex'>
            <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(appleAuthProvider)}>
                <FontAwesomeIcon icon={faApple} size='3x' fixedWidth />
              </Button>
            </div>
            <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(googleAuthProvider)}>
                <FontAwesomeIcon icon={faGoogle} size='3x' fixedWidth />
              </Button>
            </div>
            <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(microsoftAuthProvider)}>
                <FontAwesomeIcon icon={faMicrosoft} size='3x' fixedWidth />
              </Button>
            </div>
            {/* <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(facebookAuthProvider)}>
                <FontAwesomeIcon icon={faFacebookF} size='3x' fixedWidth />
              </Button>
            </div>
            <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(yahooAuthProvider)}>
                <FontAwesomeIcon icon={faYahoo} size='3x' fixedWidth />
              </Button>
            </div>
            <div className='login-item'>
              <Button variant='variant-login' size='size-login' disabled={loading} onClick={() => loginPopup(twitterAuthProvider)}>
                <FontAwesomeIcon icon={faTwitter} size='3x' fixedWidth />
              </Button>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            loading && (
              <div>
                جاري الدخول
                <span className='ms-2' />
                <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
              </div>
            )
          }
          {
            (firebaseError || error) && <ErrorMessage />
          }
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AuthUser