import { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useReactiveVar } from '@apollo/client'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompactDisc, faHouse, faMagnifyingGlass, faUser, faBars } from '@fortawesome/free-solid-svg-icons'
import { authUser } from 'lib/localState'
import ClientOnly from 'components/client.only'
import AuthUser from 'components/user.auth.comp'
import SearchLayout from 'components/layout.search.comp'
import SideMenuLayout from 'components/layout.sideMenu.comp'
import PlayerLayout from 'components/layout.player.comp'
import AwtarikaLogo from 'public/images/logo.png'

const Layout = (props) => {
  // state variable to toggle showing side menu
  const [isOpenSideMenu, setOpenSideMenu] = useState(false)

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  return (
    <div dir="rtl">
      {/* header */}
      <header className="header">
        <div className="container">
          <div className="header-inner">
            <div className="float-left">
              <Link className="logo" href='/'>
                <Image src={AwtarikaLogo} width={85} height={70} alt="أوتاريكا" />
              </Link>
            </div>
            <div className="float-right">
              <SearchLayout />
              <div className="menu-icon" onClick={() => { setOpenSideMenu(!isOpenSideMenu) }}>
                <span></span> <span></span> <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* page content */}
      <div className='page'>
        <div className="main">
          <div className="container container-wipe">
            {props.children}

            {/* Layout Bottom 6737517980 */}
            <div align="center">
              <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-format="autorelaxed"
                data-ad-client={`${process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_PUBLISHER_CLIENT}`}
                data-ad-slot="6737517980">
              </ins>
            </div>
          </div>
        </div>
      </div>

      {/* player */}
      <ClientOnly>
        <PlayerLayout />
      </ClientOnly>

      {/* side menu */}
      <Offcanvas dir='rtl' scroll={true} show={isOpenSideMenu} onHide={() => { setOpenSideMenu(false) }}>
        <SideMenuLayout />
      </Offcanvas>

      {/* mobile bottom menu */}
      <div className="menu-mobile">
        <div className="mm-flex">
          <div className="mm-icon" onClick={() => { setOpenSideMenu(false) }}>
            <Link className="mm-icon" href="/">
              <FontAwesomeIcon icon={faHouse} fixedWidth />
            </Link>
          </div>
          <div className="mm-icon" onClick={() => { setOpenSideMenu(false) }}>
            <Link className="mm-icon" href="/search-results">
              <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
            </Link>
          </div>
          <div className="mm-icon" onClick={() => { setOpenSideMenu(false) }}>
            <Link className="mm-icon" href="/user/playlists-list">
              <FontAwesomeIcon icon={faCompactDisc} fixedWidth />
            </Link>
          </div>
          <div className="mm-icon" onClick={() => { setOpenSideMenu(false) }}>
            {
              getAuthUser ? (
                <Link className="mm-icon" href={`/user/${getAuthUser.id}/${getAuthUser.slug}`}>
                  <FontAwesomeIcon icon={faUser} fixedWidth />
                </Link>
              ) : (
                <AuthUser buttonVariant="MobileMenuLogin" />
              )
            }
          </div>
          <div className="mm-icon" onClick={() => { setOpenSideMenu(!isOpenSideMenu) }}>
            <FontAwesomeIcon icon={faBars} fixedWidth />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout