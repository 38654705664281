import { gql, useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { authUser } from 'lib/localState'
import { GET_SONG_QUERY } from 'lib/graphql'
import { SHARING_OPTIONS } from 'lib/constants'
import { isMobile } from 'lib/device'
import { facebook, twitter } from 'lib/shareUrl'

const SHARE_SONG_MUTATION = gql`
  mutation shareSong ($songId: ID!, $userId: ID) {
    shareSong(songId: $songId, userId: $userId)
  }
`

const ShareSong = (props) => {
  // mutation tuple
  const [shareSong, { loading }] = useMutation(
    SHARE_SONG_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )

  // is mobile
  const showMobileVersion = (typeof navigator !== 'undefined') && isMobile(navigator.userAgent)
  const canNavShare = (typeof navigator !== 'undefined') && navigator.canShare

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // excute query to display data. the query will most likey use cache
  const { data } = useQuery(
    GET_SONG_QUERY,
    {
      variables: { id: props.songId },
    }
  )

  // in case of initial loading (or the highly unlikely case of no data found)
  if (!data?.getSong) {
    return null
  }

  // get data
  const { getSong } = data

  // function: handle onClick event
  const handleShare = (sharingOption) => {
    switch (sharingOption) {
      case SHARING_OPTIONS.FACEBOOK:
        window.open(facebook(window.location.href))
        break
      case SHARING_OPTIONS.TWITTER:
        window.open(twitter(window.location.href))
        break
      case SHARING_OPTIONS.LINK:
        navigator.clipboard.writeText(decodeURIComponent(window.location.href))
        break
    }

    // execute mutation and update the cache
    shareSong({
      variables: {
        userId: getAuthUser?.id,
        songId: props.songId,
      },
      update: (cache, { data: { shareSong } }) => {
        // if a successful share, update shares counter in the cache
        if (shareSong) {
          cache.modify({
            id: cache.identify(getSong),
            fields: {
              shares(currentValue = 0) {
                return currentValue + 1
              },
            }
          })
        }
      },
    })
  }

  // function: handle onClick event
  const handleMobileShare = async () => {
    try {
      await navigator.share({
        url: decodeURIComponent(window.location.href)
      })

      // execute mutation and update the cache
      shareSong({
        variables: {
          userId: getAuthUser?.id,
          songId: props.songId,
        },
        update: (cache, { data: { shareSong } }) => {
          // if a successful share, update shares counter in the cache
          if (shareSong) {
            cache.modify({
              id: cache.identify(getSong),
              fields: {
                shares(currentValue = 0) {
                  return currentValue + 1
                },
              }
            })
          }
        },
      })
    } catch (error) {
      // disregard errors.it could be cancelled by user
    }
  }

  // display component
  return (
    <>
      {
        props.showShare && (
          <>
            {
              (showMobileVersion && canNavShare) ? (
                <Button variant='variant-player-control-item' size='size-player-control-item' onClick={() => handleMobileShare()}>
                  <FontAwesomeIcon icon={faShare} fixedWidth />
                </Button>
              ) : (
                <Dropdown drop='down'>
                  <Dropdown.Toggle variant='variant-player-control-item' size='size-player-control-item'>
                    <FontAwesomeIcon icon={faShare} fixedWidth />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleShare(SHARING_OPTIONS.FACEBOOK)} disabled={loading}>
                      <FontAwesomeIcon icon={faFacebookF} fixedWidth />
                      <span>Facebook</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShare(SHARING_OPTIONS.TWITTER)} disabled={loading}>
                      <FontAwesomeIcon icon={faTwitter} fixedWidth />
                      <span>Twitter</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShare(SHARING_OPTIONS.LINK)} disabled={loading}>
                      <FontAwesomeIcon icon={faLink} fixedWidth />
                      <span>نسخ الرابط</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
          </>
        )
      }

      {
        props.showSharesCount && (
          <>
            {getSong.shares ? Number(getSong.shares).toLocaleString('en') : 0}
          </>
        )
      }
    </>
  )
}

export default ShareSong