import { useQuery, useReactiveVar } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { authUser, playerActions, playerTimes, playerQueue, playerStateSuppotedBrowser, playerStateShowLoading, playerStateHidePlay, playerStateError } from 'lib/localState'
import { GET_SONG_QUERY } from 'lib/graphql'
import { duration } from 'lib/display'

const PlaySong = (props) => {
  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // get player reactive vars
  const getPlayerStateSuppotedBrowser = useReactiveVar(playerStateSuppotedBrowser)
  const getPlayerStateShowLoading = useReactiveVar(playerStateShowLoading)
  const getPlayerStateHidePlay = useReactiveVar(playerStateHidePlay)
  const getPlayerStateError = useReactiveVar(playerStateError)
  const getPlayerActions = useReactiveVar(playerActions)
  const getPlayerTimes = useReactiveVar(playerTimes)
  const getPlayerQueue = useReactiveVar(playerQueue)

  // excute query to display data. the query will most likey use cache
  const { data } = useQuery(
    GET_SONG_QUERY,
    {
      variables: { id: props.songId },
    }
  )

  // in case of initial loading
  if (!data?.getSong) {
    return null
  }

  // get data
  const { getSong } = data

  // function: handle onClick event
  const handlePlay = () => {
    // play if browser is supported
    if (getPlayerStateSuppotedBrowser) {
      getPlayerActions.play(null, getSong, getAuthUser)
    }

    // update the queue
    playerQueue({
      ...getPlayerQueue,
      previous: getPlayerQueue.current ? [...getPlayerQueue.previous, getPlayerQueue.current] : getPlayerQueue.previous,
      current: getSong,
    })
  }

  // display component
  return (
    <>
      {
        props.showPlayBrandButton && (
          <div className="button-icon">
            <div className="icon">
              {
                getSong.id === getPlayerQueue.current?.id ?
                  (getPlayerStateSuppotedBrowser && !getPlayerStateError) ?
                    getPlayerStateShowLoading ? (
                      <div className="play">
                        <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                      </div>
                    ) : (
                      <>
                        <div className="play" hidden={getPlayerStateHidePlay} onClick={() => getPlayerActions.play()}>
                          <FontAwesomeIcon icon={faPlay} transform="down-.5 right-1.5" fixedWidth />
                        </div>
                        <div className="play" hidden={!getPlayerStateHidePlay} onClick={() => getPlayerActions.pause()}>
                          <FontAwesomeIcon icon={faPause} transform="down-.5" fixedWidth />
                        </div>
                      </>
                    )
                    : (
                      <div className="play">
                        <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth />
                      </div>
                    )
                  : (
                    <div className="play" onClick={() => handlePlay()}>
                      <FontAwesomeIcon icon={faPlay} transform="down-.5 right-1.5" fixedWidth />
                    </div>
                  )
              }
            </div>
          </div>
        )
      }

      {
        props.showPlayOverlayButton && (
          <>
            {
              getSong.id === getPlayerQueue.current?.id ?
                (getPlayerStateSuppotedBrowser && !getPlayerStateError) ?
                  getPlayerStateShowLoading ? (
                    <div className="play">
                      <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                    </div>
                  ) : (
                    <>
                      <div className="play" hidden={getPlayerStateHidePlay} onClick={() => getPlayerActions.play()}>
                        <FontAwesomeIcon icon={faPlay} fixedWidth />
                      </div>
                      <div className="play" hidden={!getPlayerStateHidePlay} onClick={() => getPlayerActions.pause()}>
                        <FontAwesomeIcon icon={faPause} fixedWidth />
                      </div>
                    </>
                  )
                  : (
                    <div className="play">
                      <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth />
                    </div>
                  )
                : (
                  <div className="play" onClick={() => handlePlay()}>
                    <FontAwesomeIcon icon={faPlay} fixedWidth />
                  </div>
                )
            }
          </>
        )
      }

      {
        props.showProgress && (
          <div className="play-progress-wrap">
            <div className="time">
              {duration(getSong.duration)}
            </div>
            <div className="play-progress">
              {
                getSong.id === getPlayerQueue.current?.id ? (
                  <div className="play-value" style={{ width: `${(getPlayerTimes.currentTime / getPlayerTimes.duration) * 100}%` }} />
                ) : (
                  <div className="play-value" style={{ width: `0%` }} />
                )
              }
            </div>
          </div>
        )
      }

      {
        props.showPlaysCount && (
          <>
            {getSong.plays ? Number(getSong.plays).toLocaleString('en') : 0}
          </>
        )
      }
    </>
  )
}

export default PlaySong