export function shuffleArray(inputArray) {
  // create a copy
  const outputArray = [...inputArray]

  // durstenfeld shuffle algorithm
  for (let i = outputArray.length - 1; i > 0; i--) {
    // random spot
    const j = Math.floor(Math.random() * (i + 1))

    // swap
    const temp = outputArray[i]
    outputArray[i] = outputArray[j]
    outputArray[j] = temp
  }

  // return
  return outputArray
}