import { Placeholder } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'

const SwiperLoading = () => (
  <Swiper spaceBetween={40} slidesPerView={2.7} breakpoints={{ 767: { slidesPerView: 3.7 }, 991: { slidesPerView: 5.7 } }}>
    {
      [1, 2, 3, 4, 5, 6].map(n => (
        <SwiperSlide className="single-item" key={n}>
          <div className="thumb">
            <div className='loading-img loading-img-swiper'>
            </div>
          </div>
          <div className="des">
            <div className="left">
              <p>
                <Placeholder xs={11}>
                  {
                    Array.from(Array(40), (_, x) => x).map((_, n) => (<span key={n}>&nbsp;</span>))
                  }
                </Placeholder>
              </p>
              <span>
                <Placeholder xs={8} />
              </span>
            </div>

            <div className="right d-block">
              <Placeholder as="span" xs={12} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Placeholder>
            </div>
          </div>
        </SwiperSlide>
      ))
    }
  </Swiper>
)

export default SwiperLoading