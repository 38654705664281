import { useState, useEffect } from 'react'

const ClientOnly = ({ children }) => {
  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return children
}

export default ClientOnly