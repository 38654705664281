import { useMemo } from 'react'
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createAuthLink } from 'aws-appsync-auth-link'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { cache } from 'lib/apolloCache'
import { getAuthUserIdToken } from 'components/user.auth.comp'

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
let apolloClient

function useIdTokenLink({ operationName, variables }) {
  // unconditional operations
  const operationsList = [
    `getLinkedUser`,
    `listUserRecommendedSongs`,
    `listUserLikedArtists`,
    `listUserPlayedArtists`,
    `listUserLikedSongs`,
    `listUserPlayedSongs`,
    `listUserDownloadedSongs`,
    `listUserLikedPlaylists`,
    `listUserSearches`,
    `checkUserLikeComments`,
    `checkUserLikeArtist`,
    `checkUserLikeSong`,
    `checkUserDislikeSong`,
    `checkUserLikeSongImage`,
    `checkUserDislikeSongImage`,
    `checkUserLikePlaylist`,
    `likeArtist`,
    `unlikeArtist`,
    `createSong`,
    `updateSong`,
    `likeSong`,
    `unlikeSong`,
    `dislikeSong`,
    `undislikeSong`,
    `downloadSong`,
    `deleteSong`,
    `createSongImage`,
    `likeSongImage`,
    `unlikeSongImage`,
    `dislikeSongImage`,
    `undislikeSongImage`,
    `deleteSongImage`,
    `createLyrics`,
    `updateLyrics`,
    `deleteLyrics`,
    `updateUser`,
    `deleteUser`,
    `createComment`,
    `likeComment`,
    `unlikeComment`,
    `flagComment`,
    `deleteComment`,
    `createPlaylist`,
    `updatePlaylist`,
    `addSongToPlaylist`,
    `removeSongFromPlaylist`,
    `likePlaylist`,
    `unlikePlaylist`,
    `deletePlaylist`,
    `contactUs`,
    `getUploadSignedURL`,
  ]

  // conditional operations [operation, condition]
  const conditionalOperationsList = new Map([
    ['listUserPlaylists', variables.private],
    ['searchSongs', variables.userId],
    ['listTopSearches', variables.userId],
    ['shareArtist', variables.userId],
    ['shareSong', variables.userId],
    ['playSong', variables.userId],
    ['sharePlaylist', variables.userId],
    ['playPlaylist', variables.userId],
  ])

  return (operationsList.includes(operationName) || conditionalOperationsList.get(operationName))
}

function createApolloClient() {
  // API key link
  const apiAuthLink = createAuthLink({
    auth: {
      type: 'API_KEY',
      apiKey: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY,
    },
  })

  // OpenID Connect link
  const oidcAuthLink = createAuthLink({
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: async () => await getAuthUserIdToken(),
    },
  })

  // decide which the proper link from above to use (directional link)
  const awsLink = ApolloLink.split((operation) => useIdTokenLink(operation), oidcAuthLink, apiAuthLink)

  // http link (the terminating link in the chain)
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  })

  // error link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      )
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  // create ApolloClient with AWS links and cache
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([errorLink, awsLink, httpLink]),
    cache: cache,
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })
    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])

  return store
}
