import { useState, useEffect } from 'react'
import { gql, useApolloClient, useMutation, useReactiveVar, NetworkStatus } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Button, Modal, Form, InputGroup, Collapse } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowUpFromBracket, faSpinner, faCheck, faMusic } from '@fortawesome/free-solid-svg-icons'
import { authUser, postLoginAction } from 'lib/localState'
import { GET_UPLOAD_SIGNED_URL_QUERY } from 'lib/graphql'
import { slugFriendlyRegex } from 'lib/slug'
import { getUploadFileId } from 'lib/uploadFile'
import { sanitize } from 'lib/sanitizer'
import AuthUser from 'components/user.auth.comp'
import ErrorMessage from 'components/errorMessage'

const POST_LOGIN_ACTION = 'CREATE_SONG_MODAL'
const MAX_FILE_SIZE = 20000000
const FORM_TITLE = "title"
const FORM_ARTIST = "artist"
const FORM_DESC = "desc"
const FORM_FILE = "file"
const SEARCH_AUTOCOMPLETE_ARTISTS_QUERY = gql`
  query searchAutoCompleteArtists ($query: String!, $pageSize: Int!) {
    searchAutoCompleteArtists(query: $query, pageSize: $pageSize)
  }
`
const CREATE_SONG_MUTATION = gql`
  mutation createSong ($title: String!, $artist: String!, $desc: String!, $file: ID!, $userId: ID!) {
    createSong(title: $title, artist: $artist, desc: $desc, file: $file, userId: $userId) {
      id
      slug
    }
  }
`

const CreateSong = () => {
  // modal state variables
  const [isModalOpen, setModalOpen] = useState(false)

  // file validation state variables
  const [validateErrorMessage, setValidateErrorMessage] = useState(null)
  const [songFileName, setSongFileName] = useState('ملف الأغنية:')

  // artist typeahead state variables
  const [isArtistsLoading, setArtistsLoading] = useState(false)
  const [autoSuggestOptions, setAutoSuggestOptions] = useState([])

  // transitioning to confirm 
  const [isConfirmed, setConfirmed] = useState(false)
  const [createSongInput, setCreateSongInput] = useState(null)

  // upload state variables
  const [loadingGetUrl, setLoadingGetUrl] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [errorUpload, setErrorUpload] = useState(false)

  // apollo client for query later
  const apolloClient = useApolloClient()

  // mutation tuple
  const [createSong, { loading, error: errorCreate, data, reset }] = useMutation(
    CREATE_SONG_MUTATION,
    {
      onCompleted: () => {
        resetStateVariables()
      },
      onError: (error) => {
        captureException(error)
      },
    }
  )

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // get post login action
  const getPostLoginAction = useReactiveVar(postLoginAction)

  // function: reset in case of success or modal close
  const resetStateVariables = () => {
    // reset all
    setValidateErrorMessage(null)
    setSongFileName('ملف الأغنية:')
    setArtistsLoading(false)
    setAutoSuggestOptions([])
    setLoadingGetUrl(false)
    setUploadProgress(0)
    setErrorUpload(false)
  }

  // function: reset in case of error then report
  const handleError = (errorMessage) => {
    // reset
    setValidateErrorMessage(null)
    setArtistsLoading(false)
    setAutoSuggestOptions([])
    setLoadingGetUrl(false)
    setUploadProgress(0)
    setErrorUpload(true)

    // report error
    captureException(errorMessage)
  }

  // function: validation
  const validateFile = (file) => {
    // file must be required, less than 20MB and an mp3 audio
    if (!file.name || !file.size || file.size > MAX_FILE_SIZE || !(file.type === 'audio/mp3' || file.type === 'audio/mpeg')) {
      setValidateErrorMessage('يجب أن يكون ملف الأغنية MP3 و حجمه اقل من 20MB')
      return false
    } else {
      setValidateErrorMessage(null)
      return true
    }
  }

  // function: handle onClick event
  const handleFileChange = (event) => {
    setSongFileName(event.target.files[0]?.name)
    validateFile(event.target.files[0])
  }

  // function: handle onClick event
  const handleSearch = async (query) => {
    // get suggested artists
    setArtistsLoading(true)
    const { data } = await apolloClient.query({
      query: SEARCH_AUTOCOMPLETE_ARTISTS_QUERY,
      variables: {
        query: query,
        pageSize: 5,
      },
    })

    // fill typeahead with suggestions
    setArtistsLoading(false)
    setAutoSuggestOptions(data?.searchAutoCompleteArtists)
  }

  // function: handle onSubmit event. get data from form and execute mutation
  const handleSubmit = (event) => {
    // get data from form and set its behaviour
    event.preventDefault()
    const form = event.target
    const formData = new window.FormData(form)
    const title = formData.get(FORM_TITLE)
    const artist = formData.get(FORM_ARTIST)
    const desc = formData.get(FORM_DESC).replace(/\n/g, '<br/>')
    const file = formData.get(FORM_FILE)

    // validate
    if (!validateFile(file)) {
      event.stopPropagation()
      return
    }

    // set to transition
    setConfirmed(true)
    setCreateSongInput({
      title: title,
      artist: artist,
      desc: desc,
      userId: getAuthUser.id,
      localFile: file,
    })
  }

  // function: handle onClick event
  const handleCreate = async () => {
    // reset first in case of a previous error
    setErrorUpload(false)
    reset()

    // get signed URL to upload the file to
    setLoadingGetUrl(true)
    const { data, error, networkStatus } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GET_UPLOAD_SIGNED_URL_QUERY,
    })

    // report errors
    if (!data?.getUploadSignedURL) {
      handleError('No Upload Signed URL')
    }
    if (error) {
      handleError(JSON.stringify(error))
    }
    if (networkStatus === NetworkStatus.error) {
      handleError('GET_UPLOAD_SIGNED_URL_QUERY NetworkStatus = error')
    }

    // done with signed URL. 10%
    setLoadingGetUrl(false)
    setUploadProgress(10)

    // using XMLHttpRequest rather than fetch() for its onprogress event
    const xhr = new XMLHttpRequest()

    // xhr event: track upload progress. max 10 + 85 = 95%. remaining 5% for createSong mutation
    xhr.upload.onprogress = function (event) {
      setUploadProgress(10 + Math.trunc((event.loaded / event.total) * 85))
    }

    // xhr event: track completion: both successful or not
    xhr.onloadend = function () {
      if (xhr.status == 200) {
        // execute mutation
        createSong({
          variables: {
            title: createSongInput.title,
            artist: createSongInput.artist,
            desc: createSongInput.desc,
            userId: getAuthUser.id,
            file: getUploadFileId(data.getUploadSignedURL),
          },
        })
      } else {
        handleError(`xhr.status: ${xhr.status} xhr.responseText: ${xhr.responseText}`)
      }
    }

    // xhr event: error
    xhr.onerror = function (event) {
      handleError(`xhr error. event.type: ${event.type}, event.message: ${event.message}`)
    }

    // upload file
    xhr.open(`PUT`, data.getUploadSignedURL)
    xhr.send(createSongInput.localFile)
  }

  // execute postLoginAction action after rendering because we're changing the state of another component as a result of rendering
  useEffect(() => {
    // if actions and properties match then reset and execute the action
    if (getAuthUser && getPostLoginAction?.action === POST_LOGIN_ACTION) {
      // reset
      postLoginAction(null)
      // execute
      setModalOpen(true)
    }
  }, [getAuthUser, getPostLoginAction])

  // display component
  return (
    <>
      {
        !getAuthUser ? (
          <AuthUser buttonVariant="CreateSong" postLoginAction={{ action: POST_LOGIN_ACTION }} />
        ) : (
          <Button bsPrefix="btn btn-create-song" onClick={() => { setModalOpen(true) }}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )
      }

      <Modal size="lg" show={isModalOpen} onHide={() => { setModalOpen(false); setConfirmed(false); reset(); resetStateVariables() }} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>إضافة أغنية</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Collapse in={!isConfirmed}>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <InputGroup className="mb-3">
                    <Form.Label className="form-control form-control-dark upload-row" onChange={(event) => { handleFileChange(event) }}>
                      <InputGroup.Text className="upload-file-icon">
                        <FontAwesomeIcon icon={faArrowUpFromBracket} fixedWidth />
                      </InputGroup.Text>

                      <div className='input-file-name w-100'>
                        {songFileName}
                      </div>

                      <Form.Control className="d-none" name={FORM_FILE} type="file" accept="audio/mp3" disabled={loadingGetUrl || uploadProgress > 0 || loading || data?.createSong} />
                    </Form.Label>
                  </InputGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="mb-4">
                    <Form.Control className="form-control form-control-dark" type="text" name={FORM_TITLE} disabled={loadingGetUrl || uploadProgress > 0 || loading || data?.createSong} placeholder="إسم الأغنية" pattern={slugFriendlyRegex} minLength={2} maxLength={50} required />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="mb-4">
                    <AsyncTypeahead
                      onSearch={(query) => { handleSearch(query) }}
                      isLoading={isArtistsLoading}
                      options={autoSuggestOptions}
                      filterBy={() => true}
                      promptText="اكتب اكثر للبحث"
                      searchText="جاري البحث"
                      emptyLabel="لا توجد أسماء شبيهة"
                      paginate={false}
                      positionFixed={true}
                      align='right'
                      inputProps={{
                        name: FORM_ARTIST,
                        disabled: (loadingGetUrl || uploadProgress > 0 || loading || data?.createSong),
                        className: "form-control form-control-dark",
                        pattern: slugFriendlyRegex,
                        minLength: 2,
                        maxLength: 50,
                        required: true,
                      }}
                      id='artist-name'
                      placeholder="إسم الفنان"
                      minLength={2}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="mb-4">
                    <Form.Control as="textarea" className="form-control form-control-dark" type="text" name={FORM_DESC} disabled={loadingGetUrl || uploadProgress > 0 || loading || data?.createSong} rows={5} placeholder="وصف للأغنية (الكاتب، الملحن، السنة، هاش تاق ...الخ)" minLength={10} maxLength={500} required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="mb-4" >
                    <InputGroup>
                      <Form.Check type="checkbox" disabled={loadingGetUrl || uploadProgress > 0 || loading || data?.createSong} required />
                      <span className='ms-2' />
                      <Form.Label>
                        أمتلك أنا ({getAuthUser?.username}) حقوق النشر لهذه الأغنية
                      </Form.Label>
                    </InputGroup>
                  </div>
                </div>
              </div>
              <Button variant='variant-brand' size='size-brand' type="submit">
                تأكيد
              </Button>
              <InputGroup className="my-4">
                <Form.Label className="text-danger w-100" onChange={(event) => { handleFileChange(event) }}>
                  {
                    validateErrorMessage && (
                      <>
                        {validateErrorMessage}
                      </>
                    )
                  }
                </Form.Label>
              </InputGroup>
            </Form>
          </Collapse>

          <Collapse in={isConfirmed}>
            <div>
              <div className="upload-file-name mb-4">
                <div className="icon">
                  <FontAwesomeIcon icon={faMusic} />
                </div>
                <span className="text-brand">{createSongInput?.localFile?.name}</span>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="mb-4">
                    <p className="mb-1">
                      <strong>إسم الأغنية</strong>
                    </p>
                    <p className="text-brand">
                      {createSongInput?.title}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="mb-4">
                    <p className="mb-1">
                      <strong>إسم الفنان</strong>
                    </p>
                    <p className="text-brand">
                      {createSongInput?.artist}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="mb-4">
                    <p className="mb-1">
                      <strong>وصف للأغنية</strong>
                    </p>
                    <p className="text-brand" dangerouslySetInnerHTML={{ __html: sanitize(createSongInput?.desc) }} />
                  </div>
                </div>
              </div>
              <Button variant='variant-brand' size='size-brand' onClick={() => { handleCreate() }} disabled={!getAuthUser || loadingGetUrl || uploadProgress > 0 || loading || data?.createSong}>
                {!loadingGetUrl && !uploadProgress > 0 && !loading && !data?.createSong && 'إضافة'}
                {
                  ((loadingGetUrl || uploadProgress > 0 || loading) && !(errorUpload || errorCreate)) && (
                    <>
                      جاري الإضافة
                      <span className='ms-2' />
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </>
                  )
                }
                {
                  data?.createSong && (
                    <>
                      تم إرسال طلب الإضافة
                      <span className='ms-2' />
                      <FontAwesomeIcon icon={faCheck} />
                    </>
                  )
                }
              </Button>
              <span className='ms-4' />
              <Button variant='variant-brand' size='size-brand' onClick={() => { setConfirmed(false) }} hidden={loadingGetUrl || uploadProgress > 0 || loading || data?.createSong}>
                رجوع
              </Button>
              <InputGroup className="my-4">
                <Form.Label className="text-danger w-100">
                  {
                    ((loadingGetUrl || uploadProgress > 0 || loading) && !(errorUpload || errorCreate)) && (
                      <div className="play-progress height-18">
                        <div className="play-value" style={{ width: `${uploadProgress}%` }} />
                      </div>
                    )
                  }
                </Form.Label>
                {
                  data?.createSong && (
                    <>
                      سيتم اعلامكم بالبريد الالكتروني عندما تكون الاغنية جاهزة على الموقع
                    </>
                  )
                }
              </InputGroup>
            </div>
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          {(errorUpload || errorCreate) && <ErrorMessage />}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateSong