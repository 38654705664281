import { useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import { authUser } from 'lib/localState'
import { defaultDate } from 'lib/display'
import ErrorMessage from 'components/errorMessage'

const FORM_NAME = "name"
const FORM_COMPANY = "company"
const FORM_ADDRESS = "address"
const FORM_PHONE = "phone"
const FORM_EMAIL = "email"
const FORM_MESSAGE = "message"
const FORM_SIGNATURE = "signature"
const SEND_COPYRIGHT_INFRINGEMENT_NOTICE_MUTATION = gql`
  mutation sendCopyrightInfringementNotice ($songId: ID!, $notice: CopyrightInfringementNotice!) {
    sendCopyrightInfringementNotice(songId: $songId, notice: $notice)
  }
`

const SendNoticeRegardingSong = (props) => {
  // state variables
  const [isModalOpen, setModalOpen] = useState(false)

  // mutation tuple
  const [sendCopyrightInfringementNotice, { loading, error, data, reset }] = useMutation(
    SEND_COPYRIGHT_INFRINGEMENT_NOTICE_MUTATION,
    {
      onError: (error) => {
        captureException(error)
      },
    }
  )

  // get authenticated user
  const getAuthUser = useReactiveVar(authUser)

  // function: handle onSubmit event. get data from form and execute mutation
  const handleSubmit = (event) => {
    // get data from form and set its behaviour
    event.preventDefault()
    const form = event.target
    const formData = new window.FormData(form)
    const name = formData.get(FORM_NAME)
    const company = formData.get(FORM_COMPANY)
    const address = formData.get(FORM_ADDRESS)
    const phone = formData.get(FORM_PHONE)
    const email = formData.get(FORM_EMAIL)
    const message = formData.get(FORM_MESSAGE)
    const signature = formData.get(FORM_SIGNATURE)

    // execute mutation
    sendCopyrightInfringementNotice({
      variables: {
        songId: props.song.id,
        notice: {
          name,
          company,
          address,
          phone,
          email,
          userId: getAuthUser?.id,
          songId: props.song.id,
          songTitle: props.song.title,
          artistName: props.song.artist.name,
          songUrl: encodeURI(props.song.url),
          songDesc: props.song.desc,
          message,
          signature,
        }
      },
    })
  }

  // display component
  return (
    <>
      <div className='w-100' onClick={() => { setModalOpen(true) }}>
        بلاغ حقوق ملكية
      </div>

      <Modal size="lg" show={isModalOpen} onHide={() => { setModalOpen(false); reset() }} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              بلاغ حقوق ملكية خاص بأغنية:
              <br />
              {props.song.title} - {props.song.artist.name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center text-second mb-2" dir='ltr'><small>{props.song.url}</small></div>
          <div className="text-center text-second mb-2"><small>إضافة من {props.song.user.username} بتاريخ {defaultDate(props.song.createdDate)}</small></div>
          <Form onSubmit={handleSubmit}>
            <Form.Label className="mb-2">
              بيانات المبلغ
            </Form.Label>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="text" name={FORM_NAME} disabled={loading} maxLength="100" defaultValue={getAuthUser?.username} placeholder="الإسم" required />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="text" name={FORM_COMPANY} disabled={loading} maxLength="100" placeholder="الشركة" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="text" name={FORM_ADDRESS} disabled={loading} maxLength="200" placeholder="العنوان" required />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="tel" name={FORM_PHONE} disabled={loading} maxLength="100" placeholder="الهاتف" required />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="text" name={FORM_EMAIL} disabled={loading} maxLength="100" defaultValue={getAuthUser?.emails?.[0]} placeholder="البريد الالكتروني" required />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-4">
                  <Form.Control as="textarea" className="form-control form-control-dark" type="text" name={FORM_MESSAGE} disabled={loading} rows={5} minLength="50" maxLength="500" placeholder="الرجاء كتابة رسالة عن اثبات ملكيتكم للأغنيه أعلاه" required />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <Form.Label className="mb-1">
                  كتابة الاسم الكامل هنا بمثابة توقيع الكتروني و يأكد أن البيانات أعلاه صحيحة:
                </Form.Label>
                <div className="mb-4">
                  <Form.Control className="form-control form-control-dark" type="text" name={FORM_SIGNATURE} disabled={loading} minLength="5" maxLength="50" required />
                </div>
              </div>
            </div>

            <Button variant='variant-brand' size='size-brand' type="submit" disabled={!getAuthUser || loading || data?.sendCopyrightInfringementNotice}>
              {!loading && !data?.sendCopyrightInfringementNotice && 'إرسال'}
              {
                loading && (
                  <>
                    جاري الإرسال
                    <span className='ms-2'></span>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </>
                )
              }
              {
                data?.sendCopyrightInfringementNotice && (
                  <>
                    تم الإرسال
                    <span className='ms-2'></span>
                    <FontAwesomeIcon icon={faCheck} />
                  </>
                )
              }
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {error && <ErrorMessage />}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SendNoticeRegardingSong